var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { sm: 8, xs: 24 } },
                [
                  _c("head-info", {
                    attrs: {
                      title: "我的待办",
                      content: "8个任务",
                      bordered: true,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 8, xs: 24 } },
                [
                  _c("head-info", {
                    attrs: {
                      title: "本周任务平均处理时间",
                      content: "32分钟",
                      bordered: true,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 8, xs: 24 } },
                [
                  _c("head-info", {
                    attrs: { title: "本周完成任务数", content: "24个" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-card",
        {
          staticStyle: { "margin-top": "24px" },
          attrs: { bordered: false, title: "标准列表" },
        },
        [
          _c(
            "div",
            { attrs: { slot: "extra" }, slot: "extra" },
            [
              _c(
                "a-radio-group",
                [
                  _c("a-radio-button", [_vm._v("全部")]),
                  _c("a-radio-button", [_vm._v("进行中")]),
                  _c("a-radio-button", [_vm._v("等待中")]),
                ],
                1
              ),
              _c("a-input-search", {
                staticStyle: { "margin-left": "16px", width: "272px" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "dashed", icon: "plus" },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "a-list",
            {
              attrs: {
                size: "large",
                pagination: {
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSize: 5,
                  total: 50,
                },
              },
            },
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "a-list-item",
                { key: index },
                [
                  _c(
                    "a-list-item-meta",
                    { attrs: { description: item.description } },
                    [
                      _c("a-avatar", {
                        attrs: {
                          slot: "avatar",
                          size: "large",
                          shape: "square",
                          src: item.avatar,
                        },
                        slot: "avatar",
                      }),
                      _c("a", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { attrs: { slot: "actions" }, slot: "actions" }, [
                    _c("a", [_vm._v("编辑")]),
                  ]),
                  _c(
                    "div",
                    { attrs: { slot: "actions" }, slot: "actions" },
                    [
                      _c(
                        "a-dropdown",
                        [
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c("a-menu-item", [_c("a", [_vm._v("编辑")])]),
                              _c("a-menu-item", [_c("a", [_vm._v("删除")])]),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            [
                              _vm._v("更多"),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "list-content" }, [
                    _c("div", { staticClass: "list-content-item" }, [
                      _c("span", [_vm._v("Owner")]),
                      _c("p", [_vm._v(_vm._s(item.owner))]),
                    ]),
                    _c("div", { staticClass: "list-content-item" }, [
                      _c("span", [_vm._v("开始时间")]),
                      _c("p", [_vm._v(_vm._s(item.startAt))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "list-content-item" },
                      [
                        _c("a-progress", {
                          staticStyle: { width: "180px" },
                          attrs: {
                            percent: item.progress.value,
                            status: !item.progress.status
                              ? null
                              : item.progress.status,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }